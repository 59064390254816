
























import { defineComponent, PropType } from '@vue/composition-api';
import { Trip } from '@/models/trip/Trip';

export default defineComponent({
  name: 'CrewInfoCard',
  props: {
    trip: {
      type: Object as PropType<Trip>,
      required: true,
    },
  },
  setup: () => {
    const tripStatus = (trip: Trip) : string => {
      if (trip.isActive) {
        return 'Активная поездка';
      }
      return 'Завершенная поездка';
    };

    return {
      tripStatus,
    };
  },
});
