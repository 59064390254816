































import {
  computed, defineComponent, PropType, reactive,
} from '@vue/composition-api';
import dateFormats from '@/helpers/DateFormats';
import { addMonths, isAfter, isBefore } from 'date-fns';
import RdButtonSwitcher from '@/components/base/inputs/RdButtonSwitcher.vue';
import { SimpleModel } from '@/models/base/SimpleModel';

export default defineComponent({
  name: 'RdMonthSelector',
  components: { RdButtonSwitcher },
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
    customText: {
      type: String,
      default: null,
    },
    fromDate: {
      type: Date,
      required: true,
    },
    toDate: {
      type: Date,
      required: true,
    },
    withNearestMonths: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  setup: (props, { emit }) => {
    const reactiveData = reactive({
      items: new Array<Date>(),
    });

    let loop = new Date(props.toDate);
    while (isAfter(loop, props.fromDate)) {
      reactiveData.items.push(loop);
      loop = addMonths(loop, -1);
    }

    const dataChanged = (data: Date) => {
      emit('input', data);
    };

    const text = props.customText != null ? props.customText : 'name';

    const { toOnlyTextMonthDate } = dateFormats();

    const nearestMonths = computed(() => {
      return reactiveData.items.slice(0, 2).reverse();
    });

    const nearestMonthsModels = computed(() => {
      return nearestMonths.value.map((m) => new SimpleModel({
        id: m as unknown as string,
        name: toOnlyTextMonthDate(m),
      }));
    });

    return {
      reactiveData,
      text,
      toOnlyTextMonthDate,
      nearestMonths,
      nearestMonthsModels,
      dataChanged,
    };
  },
});
