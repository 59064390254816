






















































































































import {
  computed, defineComponent, onBeforeMount, reactive,
} from '@vue/composition-api';
import RdMonthSelector from '@/components/base/inputs/RdMonthSelector.vue';
import { endOfMonth, startOfYear } from 'date-fns';
import RdDepotSwitcher from '@/components/base/inputs/RdDepotSwitcher.vue';
import RdEmployeeSelector from '@/components/base/inputs/RdEmployeeSelector.vue';
import RdTripStatusSwitcher from '@/components/base/inputs/RdTripStatusSwitcher.vue';
import { UrlConstants } from '@/helpers/UrlConstants';
import { Trip } from '@/models/trip/Trip';
import { plainToClass, Type } from 'class-transformer';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';
import TripList from '@/components/trip/TripList.vue';
import TripTaskCard from '@/components/trip/trip-task-cards/TripTaskCard.vue';
import RouteShortInfo from '@/components/trip/RouteShortInfo.vue';
import TripSummaryPanel from '@/components/trip/TripSummaryPanel.vue';
import CrewInfoCard from '@/components/trip/CrewInfoCard.vue';

class TripsResponse {
  @Type(() => Trip)
  public trips = new Array<Trip>();
}

export default defineComponent({
  name: 'Trips',
  components: {
    CrewInfoCard, TripSummaryPanel, RouteShortInfo, TripTaskCard, TripList, RdTripStatusSwitcher, RdEmployeeSelector, RdDepotSwitcher, RdMonthSelector,
  },
  setup: () => {
    const httpService = Container.resolve<HttpService>("HttpService");

    const start = startOfYear(new Date());
    const end = endOfMonth(new Date());

    const reactiveData = reactive({
      items: new Array<Trip>(),
      loading: true,
      selectedTrip: null as Trip | null,
    });

    const tripSelected = (trip: Trip) => {
      reactiveData.selectedTrip = trip;
    };

    const routes = computed(() => reactiveData.selectedTrip?.lastVersion
      .continuousWorks.flatMap((cw) => cw.routes));

    const url = `${UrlConstants.baseApi}/v1/trips?from=2021-01-01&to=2021-01-1`;

    onBeforeMount(() => {
      httpService.get<TripsResponse>(url, {
        constructorFunction: TripsResponse,
      })
        .then((data) => {
          reactiveData.items = data.data.trips;
          reactiveData.loading = false;
        })
        .catch((e) => {
          console.error(e);
          reactiveData.loading = false;
        });
    });

    return {
      reactiveData,
      start,
      end,
      routes,
      tripSelected,
    };
  },
});
