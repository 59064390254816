








import {
  defineComponent, reactive, ref, watch,
} from '@vue/composition-api';
import { plainToClass } from 'class-transformer';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';
import { UrlConstants } from '@/helpers/UrlConstants';
import { Depot } from '@/models/Depot';
import RdButtonSwitcher from '@/components/base/inputs/RdButtonSwitcher.vue';

export default defineComponent({
  name: 'RdDepotSwitcher',
  components: { RdButtonSwitcher },
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: ['input'],
  setup: (props, { emit }) => {
    const url = `${UrlConstants.baseApi}/v1/depots/select`;
    const type = Depot;

    const httpService = Container.resolve<HttpService>("HttpService");
    const reactiveData = reactive({
      items: new Array<Depot>(),
      loading: true,
    });

    httpService.get<Array<any>>(url)
      .then((data) => {
        reactiveData.items = plainToClass(type, data.data);
        reactiveData.loading = false;
      })
      .catch(() => {
        reactiveData.loading = false;
      });

    const depots = ref(props.value);

    watch(depots, (current) => {
      emit('input', current);
    });

    return {
      depots,
      reactiveData,
    };
  },
});
