








import {
  defineComponent, reactive, ref, watch,
} from '@vue/composition-api';
import { plainToClass } from 'class-transformer';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';
import { UrlConstants } from '@/helpers/UrlConstants';
import { Depot } from '@/models/Depot';
import RdButtonSwitcher from '@/components/base/inputs/RdButtonSwitcher.vue';
import { SimpleModel } from '@/models/base/SimpleModel';

export default defineComponent({
  name: 'RdTripStatusSwitcher',
  components: { RdButtonSwitcher },
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
      required: false,
      default: null,
    },
  },
  emits: ['input'],
  setup: (props, { emit }) => {
    const reactiveData = reactive({
      items: [
        new SimpleModel({
          id: 'All',
          name: 'Все',
        }),
        new SimpleModel({
          id: 'Processed',
          name: 'Обработанные',
        }),
        new SimpleModel({
          id: 'Unprocessed',
          name: 'Необработанные',
        }),
        new SimpleModel({
          id: 'Active',
          name: 'Активные',
        }),
        new SimpleModel({
          id: 'WithError',
          name: 'С ошибкой',
        }),
      ],
      loading: true,
    });

    const depots = ref(props.value);

    watch(depots, (current) => {
      emit('input', current);
    });

    return {
      depots,
      reactiveData,
    };
  },
});
