














import {
  defineComponent, PropType, reactive, ref,
} from '@vue/composition-api';
import { ClassConstructor, plainToClass } from 'class-transformer';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';

export default defineComponent({
  name: 'RdDictionarySelector',
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    isObject: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: Function as PropType<ClassConstructor<any>>,
      required: true,
      default: null,
    },
    url: {
      type: String,
      required: true,
      default: null,
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
    customText: {
      type: String,
      default: null,
    },
  },
  emits: ['input'],
  setup: (props, { emit }) => {
    const httpService = Container.resolve<HttpService>("HttpService");
    const reactiveData = reactive({
      items: [],
      loading: true,
    });

    httpService.get<Array<any>>(props.url)
      .then((data) => {
        reactiveData.items = plainToClass(props.type, data.data);
        reactiveData.loading = false;
      })
      .catch((e) => {
        console.error(e);
        reactiveData.loading = false;
      });

    const dataChanged = (data: any) => {
      emit('input', data);
    };

    const text = props.customText != null ? props.customText : 'name';

    return {
      reactiveData,
      text,
      dataChanged,
    };
  },
});
