















import { defineComponent, PropType, reactive } from '@vue/composition-api';
import { SimpleModel } from '@/models/base/SimpleModel';

export default defineComponent({
  name: 'RdButtonSwitcher',
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
      required: false,
      default: null,
    },
    data: {
      type: Array as PropType<Array<SimpleModel>>,
      required: true,
      default: [],
    },
  },
  emits: ['input'],
  setup: (props, { emit }) => {
    const reactiveData = reactive({
      selectedItems: new Array<any>(),
    });

    const dataChanged = (data: Array<any>) => {
      emit('input', data);
    };

    const itemClicked = (item: any) => {
      if (reactiveData.selectedItems.includes(item)) {
        reactiveData.selectedItems = reactiveData.selectedItems.filter((si) => si != item);
      } else {
        reactiveData.selectedItems.push(item);
      }
      dataChanged(reactiveData.selectedItems);
    };

    return {
      reactiveData,
      itemClicked,
    };
  },
});
