export class Driver {
  public id!: string

  public surname!: string;

  public name!: string;

  public middleName?: string | null;

  public code!: string

  public get initials(): string {
    const middleNameInitials = this.middleName == null ? '' : `${this.middleName[0]}.`;
    return `${this.surname} ${this.name[0]}. ${middleNameInitials}`;
  }

  public get codeAndInitials(): string {
    return `${this.code} ${this.initials}`;
  }

  constructor(init?: Partial<Driver>) {
    Object.assign(this, init);
  }
}
