
































































































































import { defineComponent, PropType } from '@vue/composition-api';
import { Trip } from '@/models/trip/Trip';
import TripSummaryPanel from '@/components/trip/TripSummaryPanel.vue';
import RouteShortInfo from '@/components/trip/RouteShortInfo.vue';
import { ContinuousWork } from '@/models/trip/ContinuousWork';
import {
  differenceInHours,
  differenceInMinutes,
  format,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import { formatDurationWithOptions } from 'date-fns/fp';
import CrewInfoCard from '@/components/trip/CrewInfoCard.vue';
import { TripTaskTypeKeys } from '@/models/trip/TripTaskTypeKeys';

export default defineComponent({
  name: 'TripList',
  components: { CrewInfoCard, RouteShortInfo, TripSummaryPanel },
  props: {
    trips: {
      type: Array as PropType<Array<Trip>>,
      required: true,
      default: [],
    },
  },
  emits: ['tripSelected'],
  setup: (props, { emit }) => {
    const routes = (trip: Trip) => {
      return trip.lastVersion.continuousWorks.flatMap((cw) => cw.routes);
    };

    const tripSelected = (trip: Trip) => {
      emit('tripSelected', trip);
    };

    const formatDuration = (duration: Duration) => {
      let hoursString = '00';

      if (duration.hours != null && duration.hours > 0) {
        hoursString = duration.hours.toString().padStart(2, '0');
      }

      const minuteString = duration.minutes!.toString().padStart(2, '0');

      return `${hoursString}:${minuteString}ч`;
    };

    const restExists = (trip: Trip, continuousWork: ContinuousWork) : boolean => {
      return continuousWork.routes
        .flatMap((r) => r.routeEvents)
        .some((re) => re.tripTask != null
          && re.tripTask.type.code === TripTaskTypeKeys.RestTask
          && re.tripVersionId === trip.lastVersion.id);
    };

    const getPlanningRestTime = (trip: Trip, continuousWork: ContinuousWork) : string => {
      const restEvent = continuousWork.routes
        .flatMap((r) => r.routeEvents)
        .find((re) => re.tripTask != null
          && re.tripTask.type.code === TripTaskTypeKeys.RestTask
          && re.tripVersionId === trip.lastVersion.id)!;

      const planningRestStartTime = new Date((restEvent.tripTask as any).start.planningExecutionTime);
      const planningRestEndTime = new Date((restEvent.tripTask as any).end.planningExecutionTime);

      const planningRestTimeDuration = intervalToDuration({
        start: planningRestStartTime,
        end: planningRestEndTime,
      });

      return formatDuration(planningRestTimeDuration);
    };

    const getActualRestTime = (trip: Trip, continuousWork: ContinuousWork) : string => {
      const restEvent = continuousWork.routes
        .flatMap((r) => r.routeEvents)
        .find((re) => re.tripTask != null
          && re.tripTask.type.code === TripTaskTypeKeys.RestTask
          && re.tripVersionId === trip.lastVersion.id)!;

      const startExecutionTime = (restEvent.tripTask as any).start.executionTime;
      const endExecutionTime = (restEvent.tripTask as any).end.executionTime;

      const actualRestStartTime = new Date(startExecutionTime);
      const actualRestEndTime = new Date(endExecutionTime);

      let actualRestTimeDuration;

      if (endExecutionTime != null) {
        actualRestTimeDuration = intervalToDuration({
          start: actualRestStartTime,
          end: actualRestEndTime,
        });

        return formatDuration(actualRestTimeDuration);
      }

      return formatDuration({
        hours: 0,
        minutes: 0,
      });
    };

    const getContinuousWorkTime = (trip: Trip, continuousWork: ContinuousWork) : string => {
      const firstEvent = continuousWork.routes[0].firstEvent(trip.lastVersion.id);
      const lastEvent = continuousWork.routes[continuousWork.routes.length - 1].lastEvent(trip.lastVersion.id);

      const duration = intervalToDuration({
        start: firstEvent.date,
        end: lastEvent.date,
      });
      return formatDuration(duration);
    };

    return {
      routes,
      tripSelected,
      restExists,
      getContinuousWorkTime,
      getPlanningRestTime,
      getActualRestTime,
    };
  },
});
