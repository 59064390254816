










































































































import { defineComponent, PropType } from '@vue/composition-api';
import { Route } from '@/models/trip/Route';
import { Trip } from '@/models/trip/Trip';
import dateFormats from '@/helpers/DateFormats';
import { plainToClass } from 'class-transformer';
import { Place } from '@/models/trip/concrete-trip-task/Place';
import RdArrow from '@/components/base/RdArrow.vue';

export default defineComponent({
  name: 'RouteShortInfo',
  components: { RdArrow },
  props: {
    trip: {
      type: Object as PropType<Trip>,
      required: true,
    },
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
  },
  setup: () => {
    const { toDate, toTime } = dateFormats();

    const beginPlace = (task: any): Place => {
      if ('place' in task) {
        return plainToClass(Place, task.place);
      }

      if ('from' in task) {
        return plainToClass(Place, task.from);
      }

      throw new Error('Place not found');
    };

    const endPlace = (task: any): Place => {
      if ('place' in task) {
        return plainToClass(Place, task.place);
      }

      if ('to' in task) {
        return plainToClass(Place, task.from);
      }

      throw new Error('Place not found');
    };

    return {
      toDate,
      toTime,
      beginPlace,
      endPlace,
    };
  },
});
